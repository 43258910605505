<template>
    <v-row class="fill-height">
            <v-col cols="12" class="text-center">
                <img
                    class=""
                    :src="gift.product_img"
                    style="width: 80vw; padding-bottom: 10px"
                />
            </v-col>
            <v-col cols="12" class="text-center">
                <h3 class="mt-3">{{ gift.brand_name }}</h3>
                <h4 class="mt-3">{{ gift.product_name }}</h4>
                <h4 class="mt-3 text-caption">유효기간 <span class="font-weight-bold">{{ gift.expire_date }}</span></h4>
            </v-col>


        <v-col cols="12" class="mt-1 pl-6 pr-6" style="max-width: 760px">
            <!-- <div class="d-flex justify-center mb-12 mt-6">
                <img src="/svg/otc_logo.svg"
                    width="120px"
                />
            </div> -->

            <ValidationObserver ref="observer"
                                v-slot="{ invalid }"
                                class="mt-8"
            >
                <form class="form"
                    style="width: 100%"
                    action=""
                    @submit.prevent="sendGifticon"
                >
                <div>
                        <label class="login_label grey--text">
                            보내는 메세지
                        </label>
                        <ValidationProvider v-slot="{ errors }"
                                            name="메세지"
                                            rules="required"
                                            mode="aggressive"
                        >
                            <v-text-field v-model="send_item.msg"
                                        class="mt-1"
                                        outlined
                                        :error-messages="errors"
                                        dense
                                        required
                                        v-on:keyup.enter="sendGifticon"
                            ></v-text-field>
                        </ValidationProvider>
                    </div>

                    <!-- <div>
                        <label class="login_label grey--text">
                            Email
                        </label>
                        <ValidationProvider v-slot="{ errors }"
                                            name="Email"
                                            rules="required|email"
                                            mode="aggressive"
                        >
                            <v-text-field v-model.trim="send_item.user_email"
                                        class="mt-1"
                                        outlined
                                        :error-messages="errors"
                                        dense
                                        required
                                        v-on:keyup.enter="sendGifticon"
                            ></v-text-field>
                        </ValidationProvider>
                    </div> -->

                    

                    <div>
                        <label class="login_label grey--text">
                            받을사람 휴대폰번호
                        </label>
                        <ValidationProvider v-slot="{ errors }"
                                            name="phone"
                                            mode="aggressive"
                                            rules="required|min:11"
                        >
                            <v-text-field v-model="send_item.phone"
                                        class="mt-1"
                                        outlined
                                        type="phone"
                                        :error-messages="errors"
                                        dense
                                        required
                                        v-on:keyup.enter="sendGifticon"
                            ></v-text-field>
                        </ValidationProvider>
                    </div>

                    <div>
                        <label class="login_label grey--text">
                            받는사람 이메일
                        </label>
                        <ValidationProvider v-slot="{ errors }"
                                            name="phone"
                                            mode="aggressive"
                                            rules="required|min:11"
                        >
                            <v-text-field v-model="send_item.email"
                                        class="mt-1"
                                        outlined
                                        type="email"
                                        :error-messages="errors"
                                        dense
                                        required
                                        v-on:keyup.enter="sendGifticon"
                            ></v-text-field>
                        </ValidationProvider>
                    </div>

                    <div class="d-flex">
                        <v-col cols="6">
                            <v-btn block color="grey" dark @click="backPage" rounded large>
                                취소
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn block color="primary" depressed  
                                @click="sendGifticon" :disabled="invalid" style="color:white"  rounded large>
                                보내기
                            </v-btn>
                        </v-col>
                    </div>
                </form>
            </ValidationObserver>
        </v-col>


        <!-- *************************************************************************** -->
        <!-- SnackBar : Start  ********************************************************* -->
        <v-snackbar v-model="snackbar">
            {{ snackbar_msg }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        <!-- SnackBar : End  ************************************************************** -->
        <!-- ****************************************************************************** -->
        <v-overlay :value="loading_bar">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-row>
</template>

<script>
import axios from "../../../service/axios";
import { required, email, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
setInteractionMode('eager');
extend('email', {
    ...email,
    message: 'Email 형식을 확인해주세요',
});
extend('required', {
    ...required,
    message: '{_field_} 입력해주세요',
});
// extend('phone', {
//     params: ['target'],
//     validate(value, { target }) {
//         return value === target;
//     },
//     message: '-제거'
// });
extend('min', {
    ...min,
    message: '- 를 제거 하고 입력해주세요',
});

export default {
    name: "SendGift",
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    data() {
        return {
            gift: null, 
            etc_money: null,
            send_item: {
                id: null,
                // article_code: null,
                msg: '선물',
                email: null,
                phone: null,
            },
            snackbar: false,
            snackbar_msg: null,
            loading_bar:false, 
        }; // return
    },

    created() {
        window.scrollTo(0,0) 
        console.log('this parmas : ', JSON.parse(this.$route.params.gift))
        this.gift = JSON.parse(this.$route.params.gift)
        
        // this.$store.commit(
        //     {
        //         type: "setSubPage",
        //         sub_page: '충전금액',
        //     }
        // );
        // let test = this.$store.getters.getSubPage
        // console.log('Sub Page Name : ', test)
    },

    mounted() {
        
    },

    destroyed() {
        this.$store.commit({ type: "setSubPage", sub_page: "상품상세" });
        console.log('%c Destroyed.......', 'color:yellow;')
    },

    methods: {
        backPage() {
            this.$router.go(-1);
        },
        
        async sendGifticon() {
            // // this.barcode_dialog = false
            console.log('send Gfit.....')

            this.send_item.id = this.gift._id

            const params = {
                accessKey: this.$cookies.get("token"),
                options: this.send_item,
            };

            this.loading_bar = true

            let result = await axios.post("/gift/sendGift", params)
            if(result) {
                this.loading_bar = false
                this.snackbar = true;
                this.snackbar_msg = "선물을 보냈습니다.";
            }

            // deleteGift랑 같은 효과로 gift 리스트에서 삭제됨 
            // console.log('Result : ', result)
            this.$router.go(-2);
            
        },

    },
};
</script>

<style scoped>
</style>