var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('img',{staticStyle:{"width":"80vw","padding-bottom":"10px"},attrs:{"src":_vm.gift.product_img}})]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h3',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.gift.brand_name))]),_c('h4',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.gift.product_name))]),_c('h4',{staticClass:"mt-3 text-caption"},[_vm._v("유효기간 "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.gift.expire_date))])])]),_c('v-col',{staticClass:"mt-1 pl-6 pr-6",staticStyle:{"max-width":"760px"},attrs:{"cols":"12"}},[_c('ValidationObserver',{ref:"observer",staticClass:"mt-8",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"form",staticStyle:{"width":"100%"},attrs:{"action":""},on:{"submit":function($event){$event.preventDefault();return _vm.sendGifticon.apply(null, arguments)}}},[_c('div',[_c('label',{staticClass:"login_label grey--text"},[_vm._v(" 보내는 메세지 ")]),_c('ValidationProvider',{attrs:{"name":"메세지","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-1",attrs:{"outlined":"","error-messages":errors,"dense":"","required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendGifticon.apply(null, arguments)}},model:{value:(_vm.send_item.msg),callback:function ($$v) {_vm.$set(_vm.send_item, "msg", $$v)},expression:"send_item.msg"}})]}}],null,true)})],1),_c('div',[_c('label',{staticClass:"login_label grey--text"},[_vm._v(" 받을사람 휴대폰번호 ")]),_c('ValidationProvider',{attrs:{"name":"phone","mode":"aggressive","rules":"required|min:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-1",attrs:{"outlined":"","type":"phone","error-messages":errors,"dense":"","required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendGifticon.apply(null, arguments)}},model:{value:(_vm.send_item.phone),callback:function ($$v) {_vm.$set(_vm.send_item, "phone", $$v)},expression:"send_item.phone"}})]}}],null,true)})],1),_c('div',[_c('label',{staticClass:"login_label grey--text"},[_vm._v(" 받는사람 이메일 ")]),_c('ValidationProvider',{attrs:{"name":"phone","mode":"aggressive","rules":"required|min:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-1",attrs:{"outlined":"","type":"email","error-messages":errors,"dense":"","required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendGifticon.apply(null, arguments)}},model:{value:(_vm.send_item.email),callback:function ($$v) {_vm.$set(_vm.send_item, "email", $$v)},expression:"send_item.email"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","color":"grey","dark":"","rounded":"","large":""},on:{"click":_vm.backPage}},[_vm._v(" 취소 ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticStyle:{"color":"white"},attrs:{"block":"","color":"primary","depressed":"","disabled":invalid,"rounded":"","large":""},on:{"click":_vm.sendGifticon}},[_vm._v(" 보내기 ")])],1)],1)])]}}])})],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_msg)+" ")]),_c('v-overlay',{attrs:{"value":_vm.loading_bar}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }